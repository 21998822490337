const countriesByCurrency = {
    USD: "us",
    CAD: "ca",
    ZAR: "za",
    default: "gb",
};

const getCountryByCurrency = (currency) => {
    return Object.prototype.hasOwnProperty.call(countriesByCurrency, currency)
        ? countriesByCurrency[currency]
        : countriesByCurrency.default;
};

export default {
    getCountryByCurrency,
};

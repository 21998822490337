<template>
    <div class="zch-tip-dropdown" v-click-outside="closeSelf">
        <div class="text" @click="displayList">
            <span>
                {{ formatCurrency(selectedItem, currency) }}
            </span>
            <img src="../../assets/dropdown-caret.svg" alt="" />
        </div>
        <div v-if="isListDisplayed" class="zch-tip-dropdown-list">
            <div v-for="(item, key) in list" v-bind:key="key" class="item" @click="selectItem(item)">
                {{ formatCurrency(item, currency) }}
            </div>
        </div>
    </div>
</template>

<script>
import { stateHandler } from "../../main";
import { format } from "@/service/currencies";

export default {
    name: "TipDropdown",
    props: {
        passedList: Array,
        currency: String,
    },
    data() {
        return {
            list: this.passedList,
            isListDisplayed: false,
            selectedItem: this.passedList[0],
        };
    },
    methods: {
        closeSelf() {
            this.isListDisplayed = false;
        },
        displayList() {
            this.isListDisplayed = true;
        },
        selectItem(item) {
            this.selectedItem = item;
            this.$emit("update", item);
            this.closeSelf();
        },
        formatCurrency(item, currency) {
            return format(item / 100, currency);
        },
    },
    computed: {
        listClass() {
            return this.isListDisplayed ? "active" : "";
        },
    },
    directives: {
        "click-outside": {
            bind: (el, binding) => {
                const handler = (e) => {
                    if (binding.modifiers.bubble || (!el.contains(e.target) && el !== e.target)) {
                        binding.value(e);
                    }
                };
                el["__vueClickOutside__"] = handler;
                document.addEventListener("click", handler);
            },

            unbind: (el) => {
                document.removeEventListener("click", el["__vueClickOutside__"]);
                el["__vueClickOutside__"] = null;
            },
        },
    },
    mounted() {
        stateHandler.$on("onUserResetTipRadioMenu", () => {
            this.selectedItem = this.passedList[0];
        });
        stateHandler.$on("onTipInputUpdate", () => {
            this.selectedItem = this.passedList[0];
        });
    },
};
</script>

<style lang="scss" scoped>
.zch-tip-dropdown {
    align-items: center;
    padding: 6px 15px;
    border: solid 0.7px rgba(84, 0, 57, 0.4);
    background-color: #fffafd;
    border-radius: 50px;
    cursor: pointer;
    user-select: none;
    width: 190px;
    font-family: Muli, sans-serif;
    font-size: 19.2px;
    font-weight: 500;
    line-height: 1.87;
    color: $color-blackberry;

    @media screen and (max-width: 992px) {
        width: 100%;
    }

    .text {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .flag {
        width: 27px;
        margin-right: 10px;
        border-radius: 50%;
    }

    span {
        margin-right: 40px;
    }

    &-list {
        position: absolute;
        width: 250px;
        transition: 500ms;
        border: 1px solid #b3b3b355;
        background: #ffffff;
        margin-top: 10px;
        margin-left: 2px;
        overflow-y: hidden;
        border-radius: 25px;

        & > .item {
            padding: 11px 0 11px 20px;
            border-bottom: 1px solid #b3b3b355;

            &:hover {
                background: #b3b3b315;
            }

            &:last-child {
                border-bottom: 0;
            }
        }
    }
}
</style>

import axios from "axios";

const submitPaymentInfo = (idToken, body) => {
    return axios.post(`/api/payments`, body, {
        headers: {
            Authorization: idToken,
        },
    });
};

export default {
    submitPaymentInfo,
};

import axios from "axios";

const getStripePublishableKey = () => {
    return axios.get("/api/payment_methods/token").then((resp) => {
        if (resp.status !== 200 || !resp.data || !resp.data.stripe_token) {
            throw new Error("Unable to retrieve stripe token");
        }
        return resp.data.stripe_token;
    });
};

const setupPaymentMethods = (idToken) => {
    return axios
        .post(
            "/api/payment_methods/setup",
            {},
            {
                headers: {
                    Authorization: idToken,
                },
            }
        )
        .then((resp) => {
            if (resp.status !== 200 || !resp.data || !resp.data.client_secret) {
                throw new Error("Unable to retrieve stripe token");
            }
            return resp.data.client_secret;
        });
};

const submitPaymentMethod = (idToken, paymentMethod) => {
    return axios
        .post(
            "/api/payment_methods",
            {
                id: paymentMethod,
            },
            {
                headers: {
                    Authorization: idToken,
                },
            }
        )
        .then((resp) => {
            if (resp.status !== 200 || !resp.data) {
                throw new Error("Unable to submit payment method");
            }
            return resp.data;
        });
};
export default {
    getStripePublishableKey,
    setupPaymentMethods,
    submitPaymentMethod,
};

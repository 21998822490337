const isInIframe = window.parent !== window;

function emitDonation(amount, currency) {
    if (!isInIframe) {
        return;
    }

    let payload = JSON.stringify({
        event: "onDonation",
        amount: amount,
        currency: currency,
    });

    window.parent.postMessage(payload, "*");
}

export default {
    emitDonation,
};

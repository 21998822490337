<template>
    <div v-bind:class="checkboxClass" class="zch-checkbox" @click="updateSelf">
        <input :id="id" type="checkbox" :checked="toggled" class="hidden" />
        <img v-if="toggled" src="../../assets/tick.svg" :alt="iconAlt" />
    </div>
</template>

<script>
import { stateHandler } from "../../main";

export default {
    name: "Checkbox",
    props: {
        id: {
            type: String,
            default: ":(",
        },
        defaultValue: {
            type: Boolean,
            default: true,
        },
        callback: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            toggled: this.defaultValue,
        };
    },
    methods: {
        updateSelf() {
            this.toggled = !this.toggled;

            if (this.callback) {
                stateHandler.$emit(this.callback, this.toggled);
            }
        },
    },
    computed: {
        iconAlt() {
            return this.toggled ? "Uncheck the box" : "Check the box";
        },
        checkboxClass() {
            return this.toggled ? "" : "inactive";
        },
    },
};
</script>

<style lang="scss" scoped>
.zch-checkbox {
    max-width: 23px;
    min-width: 23px;
    height: 23px;
    background-color: $color-montecarlo;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    user-select: none;
    padding: 2px;
    border: 2px solid $color-montecarlo;
    &.inactive {
        border: solid 2px #879b94;
        background-color: #0000;
    }

    .hidden {
        display: none;
    }
}
</style>

import { render, staticRenderFns } from "./Charities.vue?vue&type=template&id=70560c1f&scoped=true&"
import script from "./Charities.vue?vue&type=script&lang=js&"
export * from "./Charities.vue?vue&type=script&lang=js&"
import style0 from "./Charities.vue?vue&type=style&index=0&id=70560c1f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../common/temp/node_modules/.pnpm/vue-loader@15.9.7_679359cdb69c218f2f8f476b2ba08796/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70560c1f",
  null
  
)

export default component.exports
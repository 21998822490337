<template>
    <div class="details">
        <h2>My Zakat</h2>
        <div class="items">
            <div class="zakat-item">
                <div class="type">I have...</div>
                <div class="amount have">
                    {{ formatFunds(valueOwned) }}
                </div>
            </div>
            <div class="zakat-item">
                <div class="type">... I owe</div>
                <div class="amount owe">
                    {{ formatFunds(valueOwed) }}
                </div>
            </div>
            <div class="zakat-item">
                <div class="type">is equal to:</div>
                <div class="amount">
                    {{ formatFunds(valueOwned - valueOwed) }}
                </div>
            </div>
            <div class="zakat-item">
                <div class="type">Today’s Nisab:</div>
                <div class="amount">
                    {{ formatFunds(nisab) }}
                </div>
            </div>
        </div>
        <div v-if="assetsDisplayed" class="assets">
            <div class="item" v-for="(asset, key) in assets" v-bind:key="key">
                <div class="name">
                    {{ asset.name }}
                </div>
                <div class="payable">
                    <div class="amount">
                        {{ formatFunds(asset["total"]) }}
                    </div>
                </div>
                <div class="border"></div>
                <div class="zakat-to-pay">
                    <div class="notice">Zakat to pay =</div>
                    <div class="amount">
                        {{ formatFunds(asset["zakatable"], true) }}
                    </div>
                </div>
            </div>
        </div>
        <div class="zakat-total">
            <div class="info">My Zakat is:</div>
            <div class="amount">
                {{ formatFunds(zakatable, true) }}
            </div>
        </div>
        <div class="more-details" @click="invertAssetState">
            <span>See more details</span>
            <img :style="detailsArrow" src="../../assets/details-caret.svg" alt="" />
        </div>
    </div>
</template>

<script>
import { format } from "@/service/currencies";

export default {
    name: "Details",
    props: {
        valueOwed: Number,
        valueOwned: Number,
        nisab: Number,
        assetsDisplay: Boolean,
        assets: Array,
        zakatable: Number,
        currency: String,
    },
    data() {
        return {
            assetsDisplayed: this.assetsDisplay,
        };
    },
    methods: {
        invertAssetState() {
            this.assetsDisplayed = !this.assetsDisplayed;
        },

        formatFunds(funds, isInPennies = false) {
            return format(isInPennies ? funds / 100 : funds, this.currency);
        },
    },
    computed: {
        detailsArrow() {
            return !this.assetsDisplayed ? "" : "transform: rotate(180deg)";
        },
    },
};
</script>

<style lang="scss" scoped>
.details {
    width: 30%;
    border-radius: 14px;
    box-shadow: 0 4px 23px 0 rgba(0, 0, 0, 0.11);
    background-color: #ffffff;
    height: max-content;

    @media screen and (max-width: 992px) {
        width: 100%;
        background-color: unset;
        box-shadow: unset;
        flex-wrap: wrap;
        display: flex;
        flex-direction: column;
    }

    h2 {
        font-family: Muli, sans-serif;
        font-size: 28px;
        font-weight: 800;
        line-height: 0.92;
        color: $color-cod-gray;
        width: 100%;
        text-align: center;
        margin-bottom: 32px;
    }
    .items {
        @media screen and (max-width: 992px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
        }
    }
    .more-details {
    }
    .zakat-item {
        margin: 0 22px;
        padding-bottom: 20px;
        border-bottom: 2px solid #eeeeee;

        @media screen and (max-width: 992px) {
            flex: 0 0 48%;
            width: 48%;
            margin: 0 0 15px 0;
            border-radius: 14px;
            box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.04);
            background-color: #ffffff;
            padding: 15px;
        }

        @media screen and (max-width: 450px) {
            flex: 0 0 100%;
            width: 100%;
        }

        &:not(:first-child) {
            margin-top: 20px;
            @media screen and (max-width: 992px) {
                margin-top: 0;
            }
        }

        &:last-child {
            border-bottom: none;
            @media screen and (max-width: 992px) {
                margin-top: 0;
            }
        }

        .type {
            font-family: Muli, sans-serif;
            font-size: 20px;
            font-weight: 500;
            line-height: 1.2;
            color: #6a6a6a;
        }
        .amount {
            font-family: Muli, sans-serif;
            font-weight: 800;
            line-height: 0.86;
            color: #191919;
            margin-top: 6px;
            font-size: 140%;

            &.have {
                color: #78d788;
            }
            &.owe {
                color: #ff655c;
            }
        }
    }
    .assets {
        border-top: 1px solid #{$color-blackberry}44;

        .item {
            margin: 10px 22px 0 22px;
            font-family: Muli, sans-serif;
            font-size: 18px;
            font-weight: 600;
            line-height: 1.33;
            color: $color-blackberry;
            border-bottom: 2px solid #eeeeee;
            padding-bottom: 5px;

            @media screen and (max-width: 992px) {
                border-radius: 14px;
                box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.04);
                background-color: #fff;
                padding: 15px;
                margin: 20px 0 0 0;

                &:last-child {
                    margin-bottom: 20px;
                }
            }

            &:last-child {
                border-bottom: none;
            }

            .name {
            }
        }
        .payable {
            display: flex;
            justify-content: space-between;
            .amount {
                font-size: 20px;
                font-weight: bold;
                line-height: 1.2;
                color: $color-cod-gray;
            }
            .percentage {
                font-size: 16px;
                font-weight: 500;
                color: #aeaeae;
            }
        }
        .border {
            width: 100%;
            height: 1px;
            background-image: url("../../assets/linebreak-large.svg");
            margin: 9px 0 6px 0;
        }
        .zakat-to-pay {
            display: flex;
            justify-content: space-between;
            .notice {
                font-family: Muli, sans-serif;
                font-size: 16px;
                line-height: 1.33;
                font-weight: 400;
                color: #6a6a6a;
            }

            .amount {
                font-family: Muli, sans-serif;
                font-size: 18px;
                font-weight: bold;
                line-height: 1.33;
                color: $color-cod-gray;
            }
        }
    }
    .zakat-total {
        background-color: #e8faf4;
        padding: 35px 0;
        text-align: center;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;

        @media screen and (max-width: 992px) {
            box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.04);
            border: solid 0.7px #a6dfcc;
            background-color: #ecfbf6;
            border-radius: 14px;
        }

        .info {
            font-family: Muli, sans-serif;
            font-size: 20px;
            font-weight: 600;
            line-height: 1.2;
            color: #000000;
        }

        .amount {
            font-family: Muli, sans-serif;
            font-size: 42px;
            font-weight: 800;
            line-height: 0.57;
            color: $color-montecarlo;
            margin-top: 13px;
        }
    }
    .more-details {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 11px 0;
        background-color: #ddf0e9;
        color: #485450;
        cursor: pointer;
        transition: opacity 500ms;
        &:hover {
            opacity: 0.6;
        }

        img {
            margin-left: 8px;
            transition: 500ms;
        }

        @media screen and (max-width: 992px) {
            border-radius: 50px;
            border: solid 1px #cecece;
            background: #f8f9fb;
            padding: 15px 30px;
            width: fit-content;
            margin: 36px auto;
        }
    }
}
</style>

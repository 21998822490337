export const calculateFee = (amount, currency) => {
    const baseFee = baseFeeAmount[currency.toUpperCase()];
    return Math.ceil((amount + baseFee) / (1 - feePercentage)) - amount;
};

const baseFeeAmount = {
    AUD: 30,
    CAD: 30,
    GBP: 20,
    USD: 30,
    ZAR: 30,
};

const feePercentage = 0.029;
